"use client";
import React, { useState, useEffect } from "react";
import { useSsr } from "@/hooks";
import Link from "next/link";
import Image from "next/image";

function NotFound() {
    const [screenH, setScreenH] = useState<number>();
    const { isBrowser } = useSsr();
    useEffect(() => {
        if (isBrowser) {
            setScreenH(window.innerHeight);
        }
    }, [isBrowser]);

    return (
        <div className="flex flex-col items-center justify-center gap-y-24 w-full" style={{ height: screenH }}>
            <Image src={"/404.svg"} alt="" quality={100} unoptimized={true} priority width={500} height={500} />
            <Link href={"/"} className="uppercase bg-blue-400 font-semibold text-white rounded-xl pt-1 pb-1 pr-3 pl-3 md:text-2xl">
                Back to home{" "}
            </Link>
        </div>
    );
}

export default NotFound;
